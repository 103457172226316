import React from 'react';

import Contact from '../../../components/contact';
import Hero from '../../../components/hero';
import Layout from '@src/components/layout';

import * as styles from './savings.module.css';

import brev_till_insattare_avs_fusionen from '../../../../static/pdfs/brev_till_insattare_avs_fusionen.pdf';

const BrevTillInsattare = () => {
  return (
    <Layout newStyle>
      <Hero
        title="Rocker Spar information om fusion m.m."
        subtitleMarkdown="Klicka på länkarna nedan för att ladda ner"
      />
      <div className="container">
        <div
          style={{
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <a
            className={styles.link}
            href={brev_till_insattare_avs_fusionen}
            target="blank"
          >
            Information om fusion m.m.
          </a>
        </div>
      </div>
      <Contact />
    </Layout>
  );
};

export default BrevTillInsattare;
